<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>주소 조회</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <!--조회-->
                <div class="jh-search-form">
                    <table>
                        <colgroup>
                            <col width="30px">
                            <col width="350px">
                            <col>
                        </colgroup>
                        <tr>
                            <th><label>주소</label></th>
                            <td><input type="text" class="jh-form" v-model="keyword" @keyup.enter="searchBtn"></td>
                            <td class="has-search"><v-btn class="jh-btn is-search" @click="searchBtn">조회</v-btn></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="2">
                                <p class="jh-info-txt is-fill is-blue">도로명, 지번에 대한 통합 검색이 가능합니다. ( 예: 반포대로 58, 여의도동 28, 여의도동 KT 등 )</p></td>
                        </tr>
                    </table>
                </div>
                <!--//조회-->

                <!--리스트-->
                <div class="jh-form-wrap is-p-5">
                    <p class="jh-no-data" v-if="this.items.length === 0"><span>조회된 주소가 없습니다.</span></p>
                    <!-- <v-virtual-scroll class="jh-list-address" :items="items" item-height="65" height="460" v-if="this.items.length > 0">
                        <template v-slot="{ item }">
                            <div class="jh-list-address-item" :key="item.index" :class="{'is-active' : selectedItem === item }" @click="listClick(item)">
                                <div>
                                    <label class="is-new">도로명</label>
                                    <p>{{ item.address1 }}</p>
                                </div>
                                <div>
                                    <label class="is-old">지번</label>
                                    <p>{{ item.address2 }}</p>
                                </div>
                            </div>
                        </template>
                    </v-virtual-scroll> -->
                    <v-virtual-scroll class="jh-list-address" :items="items" item-height="65" height="460" v-if="this.items.length > 0">
                        <template v-slot="{ item, index }">
                            <div tabindex="-1" class="jh-list-address-item" :key="index" :class="{'is-active' : selectedItem === item }" @click="listClick(item)" @dblclick="listDbClick(item)">
                                <div>
                                    <label class="is-new">도로명</label>
                                    <p>({{item.zPostNo.slice(0, -1)}}) {{item.zRoadAddr}}</p>
                                </div>
                                <div>
                                    <label class="is-old">지번</label>
                                    <p>({{item.zPostNo.slice(0, -1)}}) {{item.zLotNoAddr}}</p>
                                </div>
                            </div>
                        </template>
                    </v-virtual-scroll>
                </div>
                <!--//리스트-->
            </main>
            <footer>
                <v-btn class="jh-btn is-md has-icon" :disabled="szCont === 'N'" @click="searchBtn('Y')">
                    <span>연속조회</span><i class="jh-icon-angle-down is-blue"></i>
                </v-btn>
                <v-btn class="jh-btn is-md is-main" @click="close">선택</v-btn>
            </footer>
        </div>
    </v-app>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
  
export default {
    name: "MENU_E010501P02", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
    },
    data() {
        return {
            selectedItem: null,
            // items: Array.from({length: 100}).map((v, k) => ({
            //     index: `${k+1}`,
            //     address1: '서울특별시 영등포구 여의서로 120 (여의도동)',
            //     address2: '서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소'
            // })),
            keyword: '',
            items: {},
            szCont: '',
            szContKey: '',
        }
    },
    created() { 
    },
    mounted() {
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener && opener.opener) {
            if (!opener.opener.popups) opener.opener.popups = [];
                opener.opener.popups.push(self);
        }
        else if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
    },
    computed: {
        items () {
            return Array.from({length: 100}, (k, v) => v + 1)
        },
        initHeaders(){
            return {
                SERVICE: "phone.main.post-addr",
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    methods: {
        close(){
            this.sendToOpener(this.selectedItem);
            self.close();
        },
        sendToOpener(obj) {
            if (opener == null) {
                return;
            }

            let sendStr = JSON.stringify(obj);

            window.opener.postMessage(sendStr, '*');
        },
        listClick(item){
            if(this.selectedItem === item){
                this.selectedItem = null;
            }else{
                this.selectedItem = item;
            }
        },
        listDbClick(item) {
            if(this.selectedItem === item){
                this.selectedItem = null;
            }else{
                this.selectedItem = item;
            }
            this.close();
        },
        async searchBtn(param) {
            if(this.keyword != '' || !this.mixin_isEmpty(this.keyword)) {
                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: this.initHeaders.METHOD,
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData: {}
                };
    
                requestData.headers["URL"] = "/api/phone/main/post-addr/inqire";
                requestData.headers["METHOD"] = "select";
                requestData.headers["ASYNC"] = false;
                requestData.sendData["ADDR_NO"] = this.keyword;
                
                if(param === 'Y') {
                    requestData.sendData["szCont"] = 'Y';
                    requestData.sendData["szContKey"] = this.szContKey;
                } else {
                    requestData.sendData["szCont"] = 'N';
                }
    
                const response = await this.common_postCall(requestData);
                const data = response.DATA;
                const header = response.HEADER;
    
                if(header.ERR_FLAG) {
                    this.common_alert(`원장 데이터 수신 불가 [IT 담당자 문의요망]`, 'error');
                } else {
                    this.szCont = response.HEADER.szCont;
                    this.szContKey = response.HEADER.szContKey;
                    this.items = data;
                } 
            } else {
                this.common_alert(`검색어를 입력해주세요.`, 'info');
            }
        }
    },
};
</script>

<style></style>  